import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../config/axiosInstance';
// const url2 = `https://outcrypt.nfboat.com/ports_data_geoloc?latitude=49.4469&longitude=2.68583&index=1`;

// Get all ports
export const getMechanics = createAsyncThunk(
    'mechanic/getMechanics',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`mechanics_data`);
            // const { data } = await axiosInstance.get(`${url2}`);

            const modifiedData = data.map((item) => {
                return {
                    ...item,
                    category: 'mechanics',
                };
            });

            return fulfillWithValue(modifiedData);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Get Port details
export const getMechanicdetails = createAsyncThunk(
    'mechanic/getMechanicdetails',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `mechanics_details?id=${id}`,
            );

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Get Port details
export const getReviews = createAsyncThunk(
    'mechanic/getReviews',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=Jp%Racing&inputtype=textquery&key=AIzaSyA9q6meaqnTibQlAh7c2xNfCDzMQea1W_c`,
            );

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Add to Favorite
export const addToFavorite = createAsyncThunk(
    'mechanic/addToFavorite',
    async ({ id, cookie }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `user_add_mechanic_favorite?data=${id}&cookie=${cookie}`,
            );
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

export const mechanicSlice = createSlice({
    name: 'port',
    initialState: {
        mechanics: [],
        filterMechanics: [],
        loading: false,
        error: null,
        mechanicDetails: null,
        favorites: [],
        isSubscribed: null,
        allMechanicsData: [],
        page: 2,
        reviews: null,
    },
    extraReducers: (builder) => {
        // Get Reviews
        builder.addCase(getReviews.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getReviews.fulfilled, (state, action) => {
            state.loading = false;
            state.reviews = action.payload;
        });

        builder.addCase(getReviews.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Get Mechanic List
        builder.addCase(getMechanics.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getMechanics.fulfilled, (state, action) => {
            state.loading = false;
            state.mechanics = action.payload;
            state.filterMechanics = action.payload;
            state.allMechanicsData = action.payload.slice(0, 40);
            state.error = null;
        });

        builder.addCase(getMechanics.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Get Mechanic Details
        builder.addCase(getMechanicdetails.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getMechanicdetails.fulfilled, (state, action) => {
            state.loading = false;
            state.mechanicDetails = action.payload;
            state.error = null;
        });

        builder.addCase(getMechanicdetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },

    reducers: {
        filterMechanicByRegion: (state, action) => {
            state.filterMechanics = state.mechanics.filter((mechanic) => {
                return (
                    mechanic.port_region
                        .toLowerCase()
                        .indexOf(action.payload.toLowerCase()) !== -1
                );
            });
        },
        fetchMoreData: (state) => {
            state.allMechanicsData = state.filterMechanics.slice(
                0,
                state.page * 40,
            );

            state.page += 1;
        },
    },
});
export const { filterMechanicByRegion, fetchMoreData } = mechanicSlice.actions;
export default mechanicSlice.reducer;
