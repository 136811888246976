import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../config/axiosInstance';
import Cookies from 'js-cookie';

// const url2 = `https://outcrypt.nfboat.com/ports_data_geoloc?latitude=49.4469&longitude=2.68583&index=1`;

// Get all ports
export const getPorts = createAsyncThunk(
    'port/getPorts',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`/ports_data`);
            // const { data } = await axiosInstance.get(`${url2}`);
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Get Port details
export const getPortdetails = createAsyncThunk(
    'port/getPortdetails',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`/port_details?id=${id}`);

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Get Port details
export const addSubscription = createAsyncThunk(
    'port/addSubscription',
    async (email, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`/mailing?email=${email}`);

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Add to Favorite
export const addToFavorite = createAsyncThunk(
    'port/addToFavorite',
    async ({ id, cookie }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `user_add_port_favorite?data=${id}&cookie=${cookie}`,
            );
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);
// Remove from Favorite
export const removeToFavorite = createAsyncThunk(
    'port/removeToFavorite',
    async ({ id, cookie }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `user_remove_port_favorite?data=${id}&cookie=${cookie}`,
            );
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);
// Remove from Favorite
export const getFavoritePorts = createAsyncThunk(
    'port/getFavoritePorts',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        const cookie = Cookies.get('cookie');
        try {
            const {
                data: { result },
            } = await axiosInstance.get(
                `get_data?type=ports_favorites&cookie=${cookie}`,
            );
            return fulfillWithValue(result[0]);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

export const portSlice = createSlice({
    name: 'port',
    initialState: {
        ports: [],
        filterPorts: [],
        loading: false,
        error: null,
        portDetails: null,
        favorites: [],
        isUpdateFavorite: null,
        isSubscribed: null,
        allPortsData: [],
        page: 2,
    },

    extraReducers: (builder) => {
        // remove to favorite
        builder.addCase(removeToFavorite.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(removeToFavorite.fulfilled, (state, action) => {
            state.loading = false;
            state.isUpdateFavorite = !action.payload?.failure;
            state.error = null;
        });

        builder.addCase(removeToFavorite.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // add to favorite
        builder.addCase(addToFavorite.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addToFavorite.fulfilled, (state, action) => {
            state.loading = false;
            state.isUpdateFavorite = !action.payload?.failure;
            state.error = null;
        });

        builder.addCase(addToFavorite.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Get favorite port list
        builder.addCase(getFavoritePorts.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getFavoritePorts.fulfilled, (state, action) => {
            state.loading = false;
            state.favorites = action.payload;
            state.error = null;
        });

        builder.addCase(getFavoritePorts.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Get Port List
        builder.addCase(addSubscription.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(addSubscription.fulfilled, (state) => {
            state.loading = false;
            state.error = null;
            state.isSubscribed = true;
        });

        builder.addCase(addSubscription.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isSubscribed = false;
        });

        // Get Port List
        builder.addCase(getPorts.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getPorts.fulfilled, (state, action) => {
            state.loading = false;
            state.ports = action.payload;
            state.filterPorts = action.payload;
            state.allPortsData = action.payload.slice(0, 40);
            state.error = null;
        });

        builder.addCase(getPorts.rejected, (state, action) => {
            state.loading = false;
            state.ports = [];
            state.error = action.payload;
        });

        // Get port Details
        builder.addCase(getPortdetails.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getPortdetails.fulfilled, (state, action) => {
            state.loading = false;
            state.portDetails = action.payload;
            state.error = null;
        });

        builder.addCase(getPortdetails.rejected, (state, action) => {
            state.loading = false;
            state.portDetails = null;
            state.error = action.payload;
        });
    },

    reducers: {
        addFavorites: (state, action) => {
            // if (!state.favorites.includes(action.payload)) {
            state.favorites.push(action.payload);
            // }
        },
        removeFavorites: (state, action) => {
            // if (favorites.includes(port)) {
            const newArr = state.favorites.filter(
                (itm) => itm !== action.payload,
            );
            state.favorites = newArr;
            // }
        },
        resetFavoritePort: (state) => ({
            ...state,
            isUpdateFavorite: null,
        }),
        filterPortsByRegion: (state, action) => {
            state.filterPorts = state.ports.filter((port) => {
                return (
                    port.port_region
                        .toLowerCase()
                        .indexOf(action.payload.toLowerCase()) !== -1
                );
            });
        },
        fetchMoreData: (state) => {
            state.allPortsData = state.filterPorts.slice(0, state.page * 40);

            state.page += 1;
        },
        resetSubscribe: (state) => {
            state.isSubscribed = null;
            state.error = null;
        },
    },
});

export const {
    addFavorites,
    removeFavorites,
    fetchMoreData,
    resetSubscribe,
    filterPortsByRegion,
    resetFavoritePort,
} = portSlice.actions;
export default portSlice.reducer;
