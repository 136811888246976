import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Success = lazy(() => import('../components/Success/Success'));
const NFCardPlus = lazy(() => import('../pages/NFCardPlus/NFCardPlus'));
const ChangePasswordPage = lazy(() =>
    import(
        '../pages/Profile/Settings/SecuritySetting/ChangePassword/ChangePasswordPage'
    ),
);
const EmailVerification = lazy(() =>
    import(
        '../pages/Profile/Settings/SecuritySetting/EmailVerification/EmailVerification'
    ),
);
const HistoryOfConnexion = lazy(() =>
    import(
        '../pages/Profile/Settings/SecuritySetting/HistoryOfConnexion/HistoryOfConnexion'
    ),
);
const PhoneVerification = lazy(() =>
    import(
        '../pages/Profile/Settings/SecuritySetting/PhoneVerification/PhoneVerification'
    ),
);
// const Account = lazy(() => import('../pages/Wallet/Account/Account'));
// const CreateNFCard = lazy(() =>
//     import('../pages/Wallet/NFCards/CreateNFCard/CreateNFCard'),
// );
// const CreateNFCardFormPage = lazy(() =>
//     import('../pages/Wallet/NFCards/CreateNFCard/Form/CreateNFCardFormPage'),
// );
// const CreateNFCardManuallyPage = lazy(() =>
//     import(
//         '../pages/Wallet/NFCards/CreateNFCard/Form/CreateNFCardManuallyPage'
//     ),
// );
const Accastillage = lazy(() => import('../pages/Accastillage/Accastillage'));
const LoginPage = lazy(() => import('../pages/Authentication/Login/LoginPage'));
const RegisterPage = lazy(() =>
    import('../pages/Authentication/Register/RegisterPage'),
);
const Home = lazy(() => import('../pages/Home'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Politique = lazy(() => import('../pages/Politique'));
const Wallet = lazy(() => import('../pages/Wallet/Wallet'));
const ProtechtedRoute = lazy(() => import('./ProtechtedRoute'));
const Tutorial = lazy(() => import('../pages/Profile/HowWorks/Tutorial'));
const IconPreview = lazy(() => import('../utils/Icons/Preview/IconPreview'));
const ProfileLayout = lazy(() => import('../pages/Profile/ProfileLayout'));
const ChatMessage = lazy(() =>
    import('../components/Profile/Message/Chat/ChatMessage'),
);
const AccountSetting = lazy(() =>
    import('../pages/Profile/Settings/AccountSetting/AccountSetting'),
);
const ManageFavorite = lazy(() =>
    import('../pages/Profile/Settings/ManageFavorite/ManageFavorite'),
);
const AboutSetting = lazy(() =>
    import('../pages/Profile/Settings/AboutSetting/AboutSetting'),
);
const NotificationSetting = lazy(() =>
    import('../pages/Profile/Settings/NotificationSetting/NotificationSetting'),
);
const PrivacySetting = lazy(() =>
    import('../pages/Profile/Settings/PrivacySetting/PrivacySetting'),
);
const SecuritySetting = lazy(() =>
    import('../pages/Profile/Settings/SecuritySetting/SecuritySetting'),
);
const SettingLayout = lazy(() =>
    import('../components/Profile/Settings/Layout/SettingLayout'),
);
const AccountInformation = lazy(() =>
    import(
        '../pages/Profile/Settings/AccountSetting/AccountInformation/AccountInformation'
    ),
);
const NFCardLayout = lazy(() =>
    import('../pages/Wallet/NFCards/NFCardLayout/NFCardLayout'),
);
const ReceiveNfCard = lazy(() =>
    import('../pages/Wallet/NFCards/ReceiveNfCard'),
);
const ViewCards = lazy(() => import('../pages/Wallet/NFCards/ViewCards'));

const ProfileSetting = lazy(() => import('../pages/Profile/ProfileSetting'));
const Profile = lazy(() => import('../pages/Profile/Profile'));
const Notification = lazy(() =>
    import('../pages/Profile/Notifications/NotificationPage'),
);
const MessageChat = lazy(() => import('../pages/Profile/Message/MessagePage'));

const SearchPage = lazy(() => import('../pages/Search/Search'));
const Mechanics = lazy(() => import('../pages/Machanics/Mechanics'));
const PortDetails = lazy(() => import('../pages/PortDetails/PortDetails'));

export default function Router() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/ports" element={<Ports />} /> */}

            <Route path="/search" element={<SearchPage />} />
            <Route path="/port/:id" element={<PortDetails />} />
            <Route path="/mechanics/:id" element={<Mechanics />} />
            <Route path="/accastillage/:id" element={<Accastillage />} />

            <Route
                path="/politique-de-confidentialite"
                element={<Politique />}
            />
            {/* <Route path="/profile" element={<LoginPage />} /> */}

            <Route
                path="/profile/:user_id"
                element={
                    <ProtechtedRoute>
                        <ProfileLayout />
                    </ProtechtedRoute>
                }
            >
                <Route index element={<Profile />} />
                <Route path="tutorial" element={<Tutorial />} />
                <Route path="notification" element={<Notification />} />

                <Route path="chat" element={<MessageChat />}>
                    <Route index element={<ChatMessage />} />
                    <Route path=":conversation_id" element={<ChatMessage />} />
                </Route>

                <Route path="setting">
                    <Route index element={<ProfileSetting />} />

                    <Route element={<SettingLayout />}>
                        <Route path="account-setting">
                            <Route index element={<AccountSetting />} />
                            <Route
                                path="information"
                                element={<AccountInformation />}
                            />
                        </Route>

                        <Route path="about" element={<AboutSetting />} />
                        <Route
                            path="notification-setting"
                            element={<NotificationSetting />}
                        />
                        <Route
                            path="privacy-setting"
                            element={<PrivacySetting />}
                        />
                        <Route path="security-setting">
                            <Route index element={<SecuritySetting />} />
                            <Route
                                path="history-connexion"
                                element={<HistoryOfConnexion />}
                            />
                            <Route
                                path="change-password"
                                element={<ChangePasswordPage />}
                            />
                            <Route
                                path="phone-verify"
                                element={<PhoneVerification />}
                            />
                            <Route
                                path="email-verify"
                                element={<EmailVerification />}
                            />
                        </Route>
                    </Route>
                    <Route
                        path="manage-favorite"
                        element={<ManageFavorite />}
                    />
                </Route>
                {/* <Route path=":id" element={<UserProfile />} /> */}
            </Route>

            <Route path="/wallet">
                <Route index element={<Wallet />} />
                {/* <Route path="account">
                    <Route index element={<Account />} />
                </Route> */}

                <Route path="nfcards" element={<NFCardLayout />}>
                    <Route index element={<ReceiveNfCard />} />
                    <Route path=":card_id" element={<ViewCards />} />
                </Route>
                {/* <Route path="create" element={<CreateNFCard />} /> */}
                {/* <Route path="create/main" element={<CreateNFCardFormPage />} /> */}
                {/* <Route
                    path="create/manually"
                    element={<CreateNFCardManuallyPage />}
                /> */}
            </Route>

            <Route path="/nfcard-plus" element={<NFCardPlus />} />
            <Route path="/manage" element={<Wallet />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />

            <Route path="success" element={<Success />} />

            {/* // Icon Preview */}
            <Route path="icon" element={<IconPreview />} />

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
