import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import axiosInstance from '../config/axiosInstance';

const sessionCookie = Number(Cookies.get('cookie'));

// // Get Data
export const getDataType = createAsyncThunk(
    'auth/getDataType',
    async (type, { fulfillWithValue, rejectWithValue }) => {
        try {
            const cookie = sessionCookie || Number(Cookies.get('cookie'));

            const { data } = await axiosInstance.get(
                `get_data?type=${type}&cookie=${cookie}`,
            );

            data.type = type;

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Update Data
export const updateData = createAsyncThunk(
    'auth/updateData',
    async (
        { type, data: updateData },
        { fulfillWithValue, rejectWithValue },
    ) => {
        try {
            const cookie = sessionCookie || Number(Cookies.get('cookie'));
            const { data } = await axiosInstance.get(
                `update_data?type=${type}&data=${updateData}&cookie=${cookie}`,
            );

            data.type = type;
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Get all nfcards
export const getAllNfCards = createAsyncThunk(
    'auth/getAllNfCards',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const cookie = sessionCookie || Number(Cookies.get('cookie'));
            const { data } = await axiosInstance.get(
                `get_all_nfcards?cookie=${cookie}`,
            );

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

export const getDataSlice = createSlice({
    name: 'getData',
    initialState: {
        loading: false,
        all_nf_cards: [],
        isUpdateFail: false,
        isUpdate: null,
        updateKey: null,
        profile_picture: null,
        phonenumber: null,
        account_type: null,
        login_history: null,
        hide_activity_status: null,
        allow_sharing: null,
        disable_all_notifications: null,
        notification_boat_maintenance: null,
        notification_partner_offers: null,
        notification_document_expiration: null,
        notification_messages: null,
        hashed_password: null,
    },

    extraReducers: (builder) => {
        // Get all nf cards
        builder.addCase(getAllNfCards.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllNfCards.fulfilled, (state, action) => {
            state.loading = false;
            state.all_nf_cards = action.payload;
            state.error = null;
        });
        builder.addCase(getAllNfCards.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Update data
        builder.addCase(updateData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateData.fulfilled, (state, action) => {
            state.loading = false;
            state.isUpdate = action.payload?.type;
            state.isUpdateFail = action.payload?.failure;
            state.error = null;
        });
        builder.addCase(updateData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Get data
        builder.addCase(getDataType.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDataType.fulfilled, (state, action) => {
            state.loading = false;
            state[action.payload.type] = action.payload.result[0];
            state.error = null;
        });
        builder.addCase(getDataType.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },

    reducers: {
        resetUpdate: (state) => ({
            ...state,
            isUpdate: null,
            isUpdateFail: false,
        }),
    },
});

export const { resetUpdate } = getDataSlice.actions;

export default getDataSlice.reducer;
