import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        windowInnerWidth: window.innerWidth,
        langPopup: false,
        footer_content_key: '',
        footer_content_toggle: false,
    },

    extraReducers: () => {},

    reducers: {
        updateWindowWidth: (state, action) => {
            state.windowInnerWidth = action.payload;
        },
        changeLnagePopup: (state, action) => {
            state.langPopup = action.payload;
        },
        chnageContentFooterKey: (state, action) => {
            state.footer_content_key = action.payload;
            state.footer_content_toggle = true;
        },
        hideFooterContentToggle: (state) => {
            state.footer_content_toggle = false;
            state.footer_content_key = '';
        },
    },
});
export const {
    updateWindowWidth,
    changeLnagePopup,
    chnageContentFooterKey,
    hideFooterContentToggle,
} = commonSlice.actions;
export default commonSlice.reducer;
