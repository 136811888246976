/* eslint-disable react-hooks/exhaustive-deps */
import i18next from 'i18next';
import Cookies from 'js-cookie';
import { Suspense, useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster } from 'sonner';
import './App.scss';
import Router from './Router/Router';
import Loader from './components/Loader/Loader';
import { getUserInfo } from './store/authSlice';
import { updateWindowWidth } from './store/commonSlice';
import { getFavoritePorts, getPorts } from './store/portSlice';
import store from './store/store';
import enTranslation from './translate/en.json';
import frTranslation from './translate/fr.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Helmet } from 'react-helmet';

export default function App() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    // const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            // navigate(`/profile/${user?.first_name?.toLowerCase() + user?.last_name?.toLowerCase()}`);
            dispatch(getFavoritePorts());
        }
    }, [user]);

    useEffect(() => {
        store.dispatch(getPorts());
        const cookie =
            Cookies.get('cookie') || sessionStorage.getItem('cookie');

        // if (cookie) {
        store.dispatch(getUserInfo(cookie));
        // }
    }, []);

    i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources: {
                fr: { translation: frTranslation },
                en: { translation: enTranslation },
            },
            lng: 'fr',
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false,
                transSupportBasicHtmlNodes: true,
            },
        });

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    const handleResize = () => {
        dispatch(updateWindowWidth(window.innerWidth));
    };

    return (
        <>
            <Suspense fallback={<Loader />}>
                <Helmet>
                    <title>
                        NFBoat | Votre Compagnon de Navigation Numérique
                    </title>
                    <meta
                        name="description"
                        content="Découvrez NFBoat, la plateforme dédiée aux propriétaires de bateaux. Gérez, trouvez et connectez-vous avec simplicité. Des ports aux professionnels, tout ce dont vous avez besoin est à portée de clic. Embarquez pour une expérience nautique réinventée."
                    />
                </Helmet>
                <Router />
                <Toaster richColors />
            </Suspense>
        </>
    );
}
