import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../config/axiosInstance';

// const url2 = `https://outcrypt.nfboat.com/ports_data_geoloc?latitude=49.4469&longitude=2.68583&index=1`;

// Get all ports
export const getAccastillage = createAsyncThunk(
    'accastillage/getAccastillage',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`riggers_data`);
            // const { data } = await axios.get(`${url2}`);

            const modifiedData = data.map((item) => {
                return {
                    ...item,
                    category: 'shop',
                };
            });

            return fulfillWithValue(modifiedData);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Get Port details
export const getAccastillageDetails = createAsyncThunk(
    'accastillage/getAccastillageDetails',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `riggers_details?id=${id}`,
            );

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Get Port details
export const getReviews = createAsyncThunk(
    'accastillage/getReviews',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axios.get(
                `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=Jp%Racing&inputtype=textquery&key=AIzaSyA9q6meaqnTibQlAh7c2xNfCDzMQea1W_c`,
            );

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

export const accastillageSlice = createSlice({
    name: 'accastillage',
    initialState: {
        accastillages: [],
        filterAccastillage: [],
        loading: false,
        error: null,
        accastillageDetails: null,
        favorites: [],
        isSubscribed: null,
        allAccastillageData: [],
        page: 2,
        reviews: null,
    },
    extraReducers: (builder) => {
        // Get Reviews
        builder.addCase(getReviews.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getReviews.fulfilled, (state, action) => {
            state.loading = false;
            state.reviews = action.payload;
        });

        builder.addCase(getReviews.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Get Accastillage List
        builder.addCase(getAccastillage.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getAccastillage.fulfilled, (state, action) => {
            state.loading = false;
            state.accastillages = action.payload;
            state.filterAccastillage = action.payload;
            state.allAccastillageData = action.payload.slice(0, 40);
            state.error = null;
        });

        builder.addCase(getAccastillage.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Get Mechanic Details
        builder.addCase(getAccastillageDetails.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getAccastillageDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.accastillageDetails = action.payload;
            state.error = null;
        });

        builder.addCase(getAccastillageDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },

    reducers: {
        // filterAccastillageByRegion: (state, action) => {
        //     state.filterMechanics = state.mechanics.filter((mechanic) => {
        //         return (
        //             mechanic.port_region.toLowerCase().indexOf(action.payload.toLowerCase()) !== -1
        //         );
        //     });
        // },
        fetchMoreData: (state) => {
            state.allAccastillageData = state.filterAccastillage.slice(
                0,
                state.page * 40,
            );

            state.page += 1;
        },
    },
});
export const { fetchMoreData } = accastillageSlice.actions;
export default accastillageSlice.reducer;
