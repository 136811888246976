import { configureStore } from '@reduxjs/toolkit';
import portSlice from './portSlice';
import filtersSlice from './filtersSlice';
import authSlice from './authSlice';
import mechanicSlice from './mechanicSlice';
import accastillageSlice from './accastillageSlice';
import commonSlice from './commonSlice';
import getDataSlice from './getDataSlice';
import { api } from './api/api';

const store = configureStore({
    reducer: {
        port: portSlice,
        mechanic: mechanicSlice,
        accastillage: accastillageSlice,
        filter: filtersSlice,
        auth: authSlice,
        common: commonSlice,
        getData: getDataSlice,
        [api.reducerPath]: api.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
});

export default store;
