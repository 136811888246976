import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import axiosInstance from '../config/axiosInstance';
import axios from 'axios';
import { getCookies } from '../utils/getCookies';

const { REACT_APP_COOKIE_EXPIRE_IN } = process.env;
// https://testcrypt.nfboat.com/
// Register User
export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (
        { email, firstName, lastName, password },
        { fulfillWithValue, rejectWithValue },
    ) => {
        try {
            const { data } = await axios.get(
                `register?email=${email}&first_name=${firstName}&last_name=${lastName}&hashed_password=${password}`,
            );
            Cookies.set('cookie', JSON.stringify(data.cookie), {
                expires: Number(REACT_APP_COOKIE_EXPIRE_IN),
            });

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Login User
export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (
        { email, password, rememberMe },
        { fulfillWithValue, rejectWithValue },
    ) => {
        try {
            const { data } = await axiosInstance.get(
                `login?email=${email}&cookie=${password}`,
            );

            if (!data.failure) {
                if (rememberMe) {
                    Cookies.set('cookie', JSON.stringify(data.cookie), {
                        expires: Number(REACT_APP_COOKIE_EXPIRE_IN),
                    });
                    sessionStorage.removeItem('cookie');
                } else {
                    sessionStorage.setItem(
                        'cookie',
                        JSON.stringify(data.cookie),
                    );
                    Cookies.remove('cookie');
                }
            }

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Login User
export const getUserInfo = createAsyncThunk(
    'auth/getUserInfo',
    async (cookie, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`cookie?cookie=${cookie}`);
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Social Login user
export const getSocialLogin = createAsyncThunk(
    'auth/getSocialLogin',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`/social_login?id=${id}`);
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Forgot Password
export const forGotPassword = createAsyncThunk(
    'auth/forGotPassword',
    async ({ email }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `/reset_password?email=${email}`,
            );
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Verify confirm
export const verifyForgotPassword = createAsyncThunk(
    'auth/verifyForgotPassword',
    async ({ email, code }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `/verify_reset_password?email=${email}&code=${code}`,
            );
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Verify confirm
export const updateProfileInfo = createAsyncThunk(
    'auth/updateProfileInfo',
    async (query, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `update_personal_data?${query}`,
            );
            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Request to verify phone
export const requestToVerifyPhone = createAsyncThunk(
    'auth/requestToVerifyPhone',
    async (phone, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `request_phone_verification?phonenumber=${phone}`,
            );

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Verify phone with code
export const vefifyPhoneWithCode = createAsyncThunk(
    'auth/vefifyPhoneWithCode',
    async ({ phone, code }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(`/phone_verification_code
            ?phonenumber=${phone}&code=${code}`);

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Verify phone with code
export const deleteAccount = createAsyncThunk(
    'auth/deleteAccount',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const cookie = getCookies();
            const { data } = await axiosInstance.get(
                `delete_account?cookie=${cookie}`,
            );

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Request to verify phone
export const requestToVerifyEmail = createAsyncThunk(
    'auth/requestToVerifyEmail',
    async (email, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `request_email_verification?email=${email}`,
            );

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

// Request to verify phone
export const verifyEmailWithCode = createAsyncThunk(
    'auth/verifyEmailWithCode',
    async ({ email, code }, { fulfillWithValue, rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                `email_verification_code?email=${email}&code=${code}`,
            );

            return fulfillWithValue(data);
        } catch (error) {
            return rejectWithValue(
                error?.response?.data?.message ||
                    error?.message ||
                    'unknown error',
            );
        }
    },
);

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loading: false,
        user: null,
        isLoggedIn: null,
        failure_message: null,
        message: null,
        error: null,
        loggedInUserInfo: null,
        isSendResetToken: null,
        isConfimedFortgotPassword: null,
        isUpdate: null,
        isPhoneVerifyRquest: null,
        isVerifiedPhone: null,
        isDeleteAccount: null,
        isEmailVerifiedRquest: null,
        isEmailVerified: null,
    },

    extraReducers: (builder) => {
        // Verify email with code
        builder.addCase(verifyEmailWithCode.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(verifyEmailWithCode.fulfilled, (state, action) => {
            state.loading = false;
            state.isEmailVerified = action.payload;
            state.error = null;
        });
        builder.addCase(verifyEmailWithCode.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Request to verify email
        builder.addCase(requestToVerifyEmail.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(requestToVerifyEmail.fulfilled, (state, action) => {
            state.loading = false;
            state.isEmailVerifiedRquest = action.payload;
            state.error = null;
        });
        builder.addCase(requestToVerifyEmail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Delete Account
        builder.addCase(deleteAccount.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteAccount.fulfilled, (state, action) => {
            state.loading = false;
            state.isDeleteAccount = !action.payload?.failure;
            state.error = null;
        });
        builder.addCase(deleteAccount.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Verify phone with code
        builder.addCase(vefifyPhoneWithCode.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(vefifyPhoneWithCode.fulfilled, (state, action) => {
            state.loading = false;
            state.isVerifiedPhone = action.payload;
            state.error = null;
        });
        builder.addCase(vefifyPhoneWithCode.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Request to phone verification
        builder.addCase(requestToVerifyPhone.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(requestToVerifyPhone.fulfilled, (state, action) => {
            state.loading = false;
            state.isPhoneVerifyRquest = action.payload;
            state.error = null;
        });
        builder.addCase(requestToVerifyPhone.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Confitm with code Reset Password
        builder.addCase(updateProfileInfo.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateProfileInfo.fulfilled, (state) => {
            state.loading = false;
            state.isUpdate = true;
            state.error = null;
        });
        builder.addCase(updateProfileInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Confitm with code Reset Password
        builder.addCase(verifyForgotPassword.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(verifyForgotPassword.fulfilled, (state, action) => {
            state.loading = false;
            state.isConfimedFortgotPassword = action.payload;
            state.error = null;
        });
        builder.addCase(verifyForgotPassword.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Reset Password
        builder.addCase(forGotPassword.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(forGotPassword.fulfilled, (state, action) => {
            state.loading = false;
            state.isSendResetToken = action.payload;
            state.error = null;
        });
        builder.addCase(forGotPassword.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        // Get User Info
        builder.addCase(getUserInfo.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(getUserInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.isLoggedIn = !action.payload.failure;
            state.error = null;
        });

        builder.addCase(getUserInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isLoggedIn = false;
        });

        // Login User
        builder.addCase(loginUser.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.isLoggedIn = !action.payload.failure;
            state.failure_message = action.payload?.failure_message;
            state.error = null;
        });

        builder.addCase(loginUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isLoggedIn = false;
        });

        // Register User
        builder.addCase(registerUser.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(registerUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.isLoggedIn = !action.payload.failure;
            state.error = action.payload?.failure_message || null;
        });

        builder.addCase(registerUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isLoggedIn = false;
        });
    },

    reducers: {
        resetRecoveryPassword: (state) => ({
            ...state,
            isLoading: false,
            isSendResetToken: null,
            isConfimedFortgotPassword: null,
        }),
        resetError: (state) => ({
            ...state,
            error: null,
            failure_message: null,
        }),
        resetDelete: (state) => ({
            ...state,
            isDeleteAccount: null,
        }),
        resetUpdate: (state) => ({
            ...state,
            isUpdate: null,
        }),
        resetPhoneVerification: (state) => ({
            ...state,
            isPhoneVerifyRquest: null,
            isVerifiedPhone: null,
        }),
    },
});

export const {
    resetRecoveryPassword,
    resetError,
    resetUpdate,
    resetPhoneVerification,
    resetDelete,
} = authSlice.actions;

export default authSlice.reducer;
