import { createSlice } from '@reduxjs/toolkit';

export const filtersSlice = createSlice({
    name: 'filter',
    initialState: {
        // key: 'see all',
        key: 'ports',
    },

    reducers: {
        putFilterKey: (state, action) => {
            state.key = action.payload;
        },
    },
});
export const { putFilterKey } = filtersSlice.actions;
export default filtersSlice.reducer;
